<template>
  <div ref="wrapper">
    <div v-if="field.label && !isModal" class="row align-items-center">
      <div class="col">
        <label>
          {{ field.label }}
          <span v-if="field.required" class="red--text">*</span>
          <i
            v-if="field.helpSnippet"
            v-b-tooltip.top.noninteractive="$t(field.helpSnippet)"
            class="fal fa-question-circle ml-1"
          />
        </label>
      </div>
    </div>
    <div class="d-flex flex-nowrap">
      <div style="flex-grow: 1; max-width: 100%">
        <div v-if="showConditions">
          <div
            v-for="(condition, i) in field.value"
            :key="i"
            class="mb-3 condition"
          >
            <div v-if="i < field.value.length - 1">
              <div class="d-flex justify-space-between align-items-center mb-1">
                <label class="d-flex align-items-center condition-label">
                  <span class="text-nowrap mr-1">{{
                    $t("workflowElements.branch.configuration.condition")
                  }}</span>
                  <div
                    class="condition-label-number text-caption rounded-circle border mr-1"
                    style="padding: 0 5px"
                  >
                    {{ i + 1 }}
                  </div>
                  :
                  <input
                    v-model="condition.label"
                    class="condition-label-input form-control form-control-sm ml-1"
                    :placeholder="
                      $t('workflowElements.branch.configuration.conditionLabel')
                    "
                  />
                  <i
                    v-if="field.value.length > 2"
                    class="condition-remove fal fa-trash text-hover-primary cursor-pointer ml-1 d-none"
                    @click="removeCondition(condition)"
                  />
                </label>
                <div>
                  <button
                    class="btn btn-sm btn-clean btn-circle btn-hover-icon-primary"
                    @click="addBracket(condition, 'open')"
                  >
                    (
                  </button>
                  <button
                    class="btn btn-sm btn-clean btn-circle btn-hover-icon-primary"
                    @click="addBracket(condition, 'close')"
                  >
                    )
                  </button>
                  <button
                    class="btn btn-sm btn-clean btn-circle btn-hover-icon-primary"
                    @click="addConditionValue(condition)"
                  >
                    <i class="fal fa-plus pr-0" />
                  </button>
                </div>
              </div>
              <draggable
                v-model="condition.values"
                :group="'condition-' + i"
                @start="drag = true"
                @end="drag = false"
              >
                <div
                  v-for="(value, ii) in condition.values"
                  :key="ii"
                  :class="{ 'mb-1': ii < condition.values.length + 1 }"
                >
                  <div class="d-flex justify-space-between align-items-start">
                    <i class="fal fa-bars cursor-move mr-1 mt-3" />
                    <v-select
                      v-if="showConnectionOperator(condition, value, ii)"
                      v-model="value.connection_operator"
                      :items="connectionOperators"
                      class="form-control mr-1 connection-operator"
                      :menu-props="{ offsetY: true }"
                    />
                    <div
                      v-if="value.type === 'condition'"
                      class="w-100 d-flex justify-content-between align-items-start"
                    >
                      <FieldHelper
                        :field="{
                          type: 'string',
                          value: value.left,
                          placeholder: $t(
                            'workflowElements.branch.configuration.conditionsValues.left'
                          )
                        }"
                        :config-values="configValues"
                        :output-values="outputValues"
                        :condition-value="true"
                        :area-invalid="areaInvalid"
                        @change="setValue(value, 'left', $event)"
                      />
                      <v-select
                        v-model="value.operator"
                        :items="operators"
                        item-text="operator"
                        item-value="name"
                        class="form-control mx-1 operator w-auto"
                        :placeholder="
                          $t(
                            'workflowElements.branch.configuration.conditionsValues.operator'
                          )
                        "
                        :menu-props="{ offsetY: true }"
                        @input="operatorSelected(value, $event)"
                      />
                      <FieldHelper
                        :field="{
                          type: 'string',
                          value: value.right,
                          placeholder: $t(
                            'workflowElements.branch.configuration.conditionsValues.right'
                          )
                        }"
                        :config-values="configValues"
                        :output-values="outputValues"
                        :disabled="disableSecondField.includes(value.operator)"
                        :condition-value="true"
                        :area-invalid="areaInvalid"
                        @change="setValue(value, 'right', $event)"
                      />
                    </div>
                    <div
                      v-else
                      class="w-100 d-flex justify-content-between align-items-center"
                    >
                      <input
                        type="text"
                        :value="value.type === 'bracketOpen' ? '(' : ')'"
                        class="form-control"
                        disabled
                      />
                    </div>
                    <button
                      v-if="condition.values.length > 1"
                      class="btn btn-sm btn-clean btn-circle btn-hover-icon-primary ml-1"
                      @click="removeConditionValue(condition, value)"
                    >
                      <i class="fal fa-minus pr-0" />
                    </button>
                  </div>
                </div>
              </draggable>
              <div class="mt-1 ml-5 text-muted">
                {{ $t("workflowElements.branch.configuration.condition") }}:
                {{ conditionToString(condition) }}
              </div>
            </div>
            <div v-else>
              <label class="mt-3">{{
                $t("workflowElements.branch.configuration.lastCondition")
              }}</label>
            </div>
          </div>
          <button class="btn btn-primary mt-1" @click="addCondition">
            <i class="fal fa-plus" />{{
              $t("workflowElements.branch.configuration.addCondition")
            }}
          </button>
        </div>
        <span v-if="field.hint" class="form-text text-muted">
          {{ field.hint }}
        </span>
        <span v-if="configValue" class="form-text text-muted pl-1">
          {{ configValueSyntax(configValue) }}
        </span>
        <span v-else-if="debugValue" class="form-text text-muted pl-1">
          {{ debugValue.syntax }}
        </span>
      </div>
    </div>
    <v-menu
      v-model="showConfigValues"
      content-class="bg-white"
      absolute
      offset-y
      :min-width="width"
      :max-width="width"
      :position-x="x"
      :position-y="y"
      max-height="300"
      @focus="showMenu()"
      @blur="showMenu(false)"
    >
      <v-list-item-group
        v-if="configValuesFiltered && configValuesFiltered.length > 0"
        v-model="selectedConfigValue"
        color="primary"
      >
        <v-subheader>Config Values</v-subheader>
        <v-list-item
          v-for="(item, index) in configValuesFiltered"
          :key="index"
          v-b-tooltip.left.noninteractive="
            typeof item.value === 'object'
              ? JSON.stringify(item.value)
              : item.value
          "
          @click="setConfigValue(item)"
        >
          <v-list-item-title>{{ item.label }}</v-list-item-title>
        </v-list-item>
      </v-list-item-group>

      <v-list-item-group
        v-if="outputValuesFiltered && outputValuesFiltered.length > 0"
        color="primary"
      >
        <v-subheader>Output Values</v-subheader>
        <v-list-item
          v-for="(item, index) in outputValuesFiltered"
          :key="index"
          @click="setOutputValue(item)"
        >
          <v-list-item-title>{{ item.value }}</v-list-item-title>
        </v-list-item>
      </v-list-item-group>

      <v-list-item-group
        v-if="
          debugValuesAllowed &&
          debugValuesFiltered &&
          debugValuesFiltered.length > 0
        "
        color="primary"
      >
        <v-subheader>Debug Values</v-subheader>
        <v-list-item
          v-for="(item, index) in debugValuesFiltered"
          :key="index"
          v-b-tooltip.left.noninteractive="
            typeof item.value === 'object'
              ? JSON.stringify(item.value)
              : item.value
          "
          @click="setDebugValue(item)"
        >
          <v-list-item-title>{{ item.syntax }}</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-menu>

    <b-modal
      ref="addConfigValueModal"
      body-class="add-config-value"
      hide-footer
      hide-header
    >
      <CreateConfigValue
        :full-width="true"
        :return-to-route="false"
        :type="type"
        :value="value"
        @config-value-create-saved="configValueSaved"
        @config-value-create-cancel="$refs['addConfigValueModal'].hide()"
      />
    </b-modal>
  </div>
</template>

<script>
import CreateConfigValue from "@/components/Projects/Settings/Config/Create";
import { bus } from "@/main";
import {
  copyToClipboard,
  generateHash
} from "@/components/Tools/helperFunctions";
import { checkTime } from "@/components/Projects/Workflows/Designer/Canvas/Components/editorHelpers";

export default {
  components: {
    CreateConfigValue,
    FieldHelper: () => import("./FieldHelper")
  },
  props: {
    field: {
      type: Object
    },
    configValues: {
      type: Array
    },
    outputValues: {
      type: Array,
      default: () => []
    },
    debugValues: {
      type: Array,
      default: () => []
    },
    areaInvalid: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    node: {
      type: Object,
      default: () => {}
    },
    conditionValue: {
      type: Boolean,
      default: false
    },
    isModal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      edited: false,
      value: "",
      configValue: null,
      valueIndex: null,
      debugValue: null,
      showConfigValues: false,
      x: 0,
      y: 0,
      width: 100,
      selectedConfigValue: undefined,
      newCondition: "",
      operators: [
        { operator: "==", name: "equals" },
        { operator: "===", name: "strictEquals" },
        { operator: "!=", name: "notEquals" },
        { operator: "!==", name: "strictNotEquals" },
        { operator: ">", name: "greaterThan" },
        { operator: "<", name: "smallerThan" },
        { operator: ">=", name: "greaterEquals" },
        { operator: "<=", name: "smallerEquals" },
        { operator: "IS NULL", name: "isNull" },
        { operator: "IS NOT NULL", name: "isNotNull" },
        { operator: "IS EMPTY", name: "isEmpty" },
        { operator: "IS NOT EMPTY", name: "isNotEmpty" }
      ],
      disableSecondField: ["isNull", "isNotNull", "isEmpty", "isNotEmpty"],
      connectionOperators: [
        { text: "AND", value: "and" },
        { text: "OR", value: "or" }
      ],
      showConditions: true,
      jsonData: "",
      cancelAction: false
    };
  },
  computed: {
    isDisabled() {
      return (
        this.disabled ||
        (this.field.readonly !== undefined && this.field.readonly)
      );
    },
    inputType: function () {
      if (
        this.field.type === "int" &&
        this.value.toString().includes("{{", "}}")
      ) {
        return "text";
      }
      if (this.field.type === "string") {
        return "text";
      }
      if (this.showConfigValues) {
        return "text";
      }
      if (this.field.type === "typeCast") {
        return "text";
      }
      return "number";
    },
    validationState: function () {
      if (
        this.conditionValue &&
        !this.isDisabled &&
        !this.value &&
        this.areaInvalid
      ) {
        return "is-invalid";
      }

      if (!this.field.required || (!this.edited && !this.areaInvalid)) {
        return "";
      }
      if (
        !this.value ||
        (this.field.type === "time" && !checkTime(this.value))
      ) {
        return "is-invalid";
      }
      return "is-valid";
    },
    configValuesFlat: function () {
      let configValues = [];
      this.configValues.forEach(configValue => {
        if (configValue.type === "json") {
          Object.entries(configValue.value).forEach(value => {
            configValues.push({
              label: `${configValue.label}.${value[0]}`,
              name: `${configValue.name}.${value[0]}`,
              value: value[1],
              type: "text"
            });
          });
        } else {
          configValues.push(configValue);
        }
      });
      return configValues;
    },
    configValuesFiltered: function () {
      if (
        this.field.configValuesDisabled !== undefined &&
        this.field.configValuesDisabled
      ) {
        return [];
      }

      let values =
        this.configValuesFlat.filter(item => this.checkType(item)) ?? [];
      if (!this.value || typeof this.value !== "string") return values;
      return values.filter(item => {
        return (
          JSON.stringify(item)
            .toLowerCase()
            .includes(this.value.toString().toLowerCase()) ||
          this.configValueSyntax(item).includes(this.value)
        );
      });
    },
    outputValuesFiltered: function () {
      if (
        this.field.outputValuesDisabled !== undefined &&
        this.field.outputValuesDisabled
      ) {
        return [];
      }
      if (!this.value || typeof this.value !== "string") {
        return this.outputValues;
      }
      return this.outputValues.filter(item => {
        return (
          item.value.toLowerCase().includes(this.value.toLowerCase()) ||
          this.outputValueSyntax(item).includes(this.value)
        );
      });
    },
    debugValuesFiltered: function () {
      if (!this.value || typeof this.value !== "string") {
        return this.debugValues;
      }

      return this.debugValues.filter(item => {
        if (typeof item.value !== "string") return true;

        return (
          item.value.toLowerCase().includes(this.value.toLowerCase()) ||
          item.syntax.includes(this.value)
        );
      });
    },
    debugValuesAllowed: function () {
      return (
        Object.keys(this.field).includes("debugValues") &&
        this.field.debugValues
      );
    },
    type: function () {
      if (this.field.type === "int") {
        return "number";
      } else if (
        this.field.type === "string" ||
        this.field.type === "textarea" ||
        this.field.type === "password"
      ) {
        return "text";
      }
      return "";
    },
    selectOptions: function () {
      if (!this.field.options) {
        return [];
      }

      let options = this.field.options.map(o => {
        if (typeof o === "string") {
          o = { value: o, label: o, group: null };
        }
        let label = o.value;
        if (o.label) {
          label = o.label;
        } else if (this.$te(`${this.field.option}Options.${o.value}`)) {
          label = this.$t(`${this.field.option}Options.${o.value}`);
        }

        return {
          value: o.value,
          label: label,
          group: o.group ?? null
        };
      });

      let optionsSorted = options;
      if (this.field.sort === "desc" || this.field.sort === -1) {
        optionsSorted = options.sort((a, b) => {
          if (a.group && b.group) {
            return b.group.localeCompare(a.group);
          }
          return b.label.localeCompare(a.label);
        });
      } else if (this.field.sort === "asc" || this.field.sort === 1) {
        optionsSorted = options.sort((a, b) => {
          if (a.group && b.group) {
            return a.group.localeCompare(b.group);
          }
          return a.label.localeCompare(b.label);
        });
      }

      let newOptions = [];
      let lastGroup = null;
      optionsSorted.forEach(option => {
        if (option.group !== null && lastGroup !== option.group) {
          newOptions.push({
            header: option.group
          });
          lastGroup = option.group;
        }
        newOptions.push(option);
      });
      return newOptions;
    }
  },
  watch: {
    value: function () {
      this.edited = true;
      let newValue = this.value;

      if (typeof newValue !== "boolean" && Number(newValue)) {
        newValue = parseFloat(newValue);
      } else if (newValue === "true" || newValue === "false") {
        newValue = Boolean(newValue);
      }

      if (this.configValue) {
        newValue = this.configValueSyntax(this.configValue);
      }

      this.$set(this.field, "value", newValue);
      this.$emit("change", newValue);

      if (this.field.name === "error_handling") {
        bus.$emit("show-hide-critical-path");
      } else if (this.field.name === "functions") {
        if (this.field.options === undefined || this.field.options === null) {
          return;
        }
        const option = this.field.options.find(o => o.value === this.value);
        if (option && option.types) {
          this.node.attrs.data.input[0].value = JSON.stringify(
            option.types,
            null,
            1
          );
        }
      }
    }
  },
  mounted() {
    this.value = this.field.value ?? "";

    if (typeof this.value === "string" && this.value.startsWith("{{config")) {
      const configValue = this.configValuesFlat.find(
        cf => cf.name === this.value.replace("{{config.", "").replace("}}", "")
      );

      if (configValue) {
        this.setConfigValue(configValue);
      }
    } else if (
      typeof this.value === "string" &&
      this.value.startsWith("{{output")
    ) {
      const outputValue = this.outputValues.find(
        dv => dv.value === this.value.replace("{{output.", "").replace("}}", "")
      );
      if (outputValue) {
        this.setOutputValue(outputValue);
      }
    }

    bus.$on("fireActionFinished", this.onFireActionFinished);
  },
  destroyed() {
    bus.$off("fireActionFinished", this.onFireActionFinished);
  },
  methods: {
    onFireActionFinished() {
      this.cancelAction = false;
    },
    checkType(item) {
      if (this.type === "number") {
        return !isNaN(item.value);
      }

      return true;
      //this.type === "text" || this.field.type === "time";
    },
    onEditorChange(value) {
      this.field.value = value.html;
    },
    showMenu(state = true, i = null, key = null) {
      if (i !== null && key !== null) {
        this.valueIndex = {
          i: i,
          key: key
        };
      }

      if (!state && this.field.type === "int" && isNaN(this.value)) {
        this.value = "";
      }
      let pos = this.$refs.wrapper?.getBoundingClientRect();
      this.x = pos.x;
      this.y = pos.y + pos.height;
      this.width = pos.width;
      this.showConfigValues = state;
      this.selectedConfigValue = undefined;
    },
    configValueSyntax(configValue) {
      return `{{config.${configValue.name}}}`;
    },
    setConfigValue(configValue) {
      this.configValue = configValue;
      if (this.field.type === "json") {
        this.value[this.valueIndex.i][this.valueIndex.key] =
          this.configValueSyntax(configValue);
        return;
      }
      this.value =
        typeof configValue.value === "object"
          ? JSON.stringify(configValue.value)
          : configValue.value;
      this.debugValue = null;
    },
    outputValueSyntax(outputValue) {
      return `{{output.${outputValue.value}}}`;
    },
    setOutputValue(outputValue) {
      this.configValue = null;
      this.debugValue = null;
      this.$set(this.field, "jsonData", outputValue.data);
      if (this.field.type === "json") {
        this.value[this.valueIndex.i][this.valueIndex.key] =
          this.outputValueSyntax(outputValue);
        return;
      }
      this.value = this.outputValueSyntax(outputValue);
    },
    setDebugValue(debugValue) {
      this.debugValue = debugValue;
      this.$set(this.field, "jsonData", JSON.stringify(debugValue.value));
      if (typeof debugValue.value !== "string") {
        this.value = debugValue.syntax;
        return;
      }

      this.value = debugValue.value;
    },
    selectChange(value) {
      this.value = value;
      if (this.field.onChange !== undefined) {
        bus.$emit(
          "fireAction",
          {
            name: this.field.onChange,
            label: this.field.onChange
          },
          false
        );
      }

      setTimeout(this.checkRequiredFields, 100);
    },
    addValue() {
      let element = {};
      this.field.fields.forEach(field => {
        this.$set(element, field.name, "");
      });
      this.value.push(element);
    },
    removeValue(index) {
      this.value.splice(index, 1);
    },
    deleteValue() {
      this.resetConfigDebugValue();
      this.value = "";
      this.field.value = "";
      this.field.jsonData = null;
    },
    resetConfigDebugValue() {
      this.configValue = null;
      this.debugValue = null;
    },
    addConfigValue() {
      this.$refs["addConfigValueModal"].show();
    },
    configValueSaved(configValue) {
      this.setConfigValue(configValue);
      this.$refs["addConfigValueModal"].hide();
      bus.$emit("update-config-values");
    },
    checkRequiredFields() {
      bus.$emit("checkRequiredFields");
    },
    fireAction(field) {
      this.cancelAction = !this.cancelAction;
      bus.$emit("fireAction", field, !this.cancelAction);
    },
    addConditionValue(condition) {
      condition.values.push({
        type: "condition",
        connection_operator: "and",
        left: "",
        operator: this.operators[0].name,
        right: ""
      });
    },
    removeConditionValue(condition, value) {
      if (
        value.type === "condition" &&
        condition.values.filter(v => v.type === "condition").length <= 1
      )
        return;
      let index = condition.values.indexOf(value);
      if (index < 0) return;
      condition.values.splice(index, 1);
    },
    addBracket(condition, type = "open") {
      condition.values.push({
        type: type === "open" ? "bracketOpen" : "bracketClose",
        connection_operator: "and"
      });
    },
    addCondition() {
      let args = {
        node: this.node,
        id: generateHash()
      };
      bus.$emit("add-condition", args);
      this.reRenderList();
    },
    removeCondition(condition) {
      let configuration = this.node.attrs.data.configuration;
      let conditionsConfig = configuration.find(el => el.name === "conditions");
      let conditionsKey = configuration.indexOf(conditionsConfig);
      if (configuration[conditionsKey].value.length <= 2) return;
      let index = configuration[conditionsKey].value.indexOf(condition);
      if (index < 0) return;
      let args = {
        node: this.node,
        condition: condition
      };
      bus.$emit("remove-condition", args);
      this.reRenderList();
    },
    showConnectionOperator(condition, value, index) {
      return (
        condition.values[index - 1] &&
        condition.values[index - 1]?.type !== "bracketOpen" &&
        value.type !== "bracketClose"
      );
    },
    reRenderList() {
      this.showConditions = false;
      this.showConditions = true;
    },
    conditionToString(condition) {
      let string = "";
      condition.values.forEach((c, index) => {
        string += " ";
        if (this.showConnectionOperator(condition, c, index)) {
          let connectionOperator = this.connectionOperators.find(
            co => co.value === c.connection_operator
          ).text;
          string += connectionOperator + " ";
        }
        if (c.type === "condition") {
          let operator = this.operators.find(o => o.name === c.operator);
          string += `${c.left ?? ""} ${operator?.operator ?? ""} ${
            c.right ?? ""
          }`;
        } else if (c.type === "bracketOpen") {
          string += "(";
        } else if (c.type === "bracketClose") {
          string += ")";
        }
      });
      return string;
    },
    operatorSelected(value, operator) {
      if (this.disableSecondField.includes(operator)) {
        value.right = "";
      }
    },
    setValue(object, key, value) {
      this.$set(object, key, value);
      this.reRenderList();
    },
    copyText(text) {
      copyToClipboard(text);
      this.$toast.fire({
        icon: "info",
        title: this.$t("general.copied")
      });
    }
  }
};
</script>

<style lang="scss">
.modal-body.add-config-value {
  padding: 0;
}

.input-group-append {
  .input-group-text {
    border: 1px solid #e4e6ef;
  }
}

.v-input--selection-controls__input .v-icon {
  color: #e4ebef;
}
</style>
